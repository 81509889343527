// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('custom/tooltip');

require('jquery');
require('jquery-validation');
require('packs/sb-admin-2');
require('bootstrap');
require('custom/sweetalert.min.js');

import jquery from 'jquery';
window.$ = window.jquery = jquery;
import 'bootstrap';

let itemArr = [];

jQuery(function ($) {
  //CSRF Token Handler
  $(document).ajaxSend(function (e, xhr, options) {
    var token = $("meta[name='csrf-token']").attr('content');
    xhr.setRequestHeader('X-CSRF-Token', token);
  });

  //View Cart Interaction
  $(document).on('click', '._Cart_', function () {});

  //Add to Cart Acton
  $(document).on('click', '.add-to-cart', function () {
    var product = $(this).attr('data-product');
    var quantity = $('#item_quty').val();

    console.log('item all the qantity link');
    console.log('checking here bow');
    var product_type = $('.product-type').val();
    var site_id = $('#site_id').val();
    console.log(product_type);
    if (quantity == 0 || quantity == undefined || quantity == null) {
      quantity = 1;
    }

    if (
      product_type == '' ||
      product_type == undefined ||
      product_type == null
    ) {
      product_type = '';
    }

    var data =
      'site_id=' +
      site_id +
      '&product_id=' +
      product +
      '&quantity=' +
      quantity +
      '&product_type=' +
      product_type;
    var request = setTheRequest({
      type: 'POST',
      url: '/add-product',
      data: data,
    });

    request.done(function (response) {
      var data = JSON.parse(response);
      $('.cart-link').removeClass('hidden');
      $('._Cart_').addClass('action-cart-btn');
      $('.cart_count')
        .empty()
        .append('(' + data.items_in_cart + ')');
      fbqAddToCart();
      addToCartGA();
      swal(
        'Added To Cart 1!',
        'This Item Has Been Added To Your Cart',
        'success',
      );
    });

    request.fail(function (jqXHR, textStatus) {
      swal('Request failed: ' + textStatus);
    });
  });

  //Email Subscribe Action
  $(document).on('submit', '#new_subscriber', function (e) {
    e.preventDefault();
    var form = $('#new_subscriber');
    var data = form.serialize();
    var request = setTheRequest({
      url: '/subscribers',
      type: 'POST',
      data: data,
    });

    request.done(function (response) {
      var data = JSON.parse(response);
      if (data.success) {
        $('.subscriber-msg')
          .empty()
          .append('Thanks! We will notify you when we have more in stock.');
        $('input[type="text"]').val('');
        $('input[type="submit"]').prop('disabled', false);
        fbqLead();
        addEmailAddressGA();
      } else {
        $('.subscriber-msg')
          .empty()
          .append(
            'Uh oh. Something went wrong. Please make sure you are using a valid email.',
          );
        $('input[type="submit"]').prop('disabled', false);
      }
    });

    request.fail(function (jqXHR, textStatus) {
      var data = JSON.parse(response);
      $('.subscriber-msg')
        .empty()
        .append(
          'Uh oh. Something went wrong. We were unable to add you to our list.',
        );
      $('input[type="submit"]').prop('disabled', false);
    });
  });

  $('#item_quty').val(1);
  $('#item_quty_list').on('click', 'li', (e) => {
    let target_val = $(e.target).text();
    $('#item_quty').val(target_val);
  });

  //Privacy Policy Interaction
  if ($('.confirm-policy')) {
    $('.confirm-policy').click(function () {
      $.post('/policy-agreement', 'confirmed=true')
        .then((data) => {
          $('.cookies').remove();
        })
        .catch((data) => {});
    });
    $('.cookies__close').click(function () {
      $.post('/close-cookies', 'closed=true')
        .then((data) => {
          $('.cookies').remove();
        })
        .catch((data) => {});
    });
  }

  //Mobile Menu Toggle Interaction
  var menuToggle = document.getElementById('menuToggle');
  var menu = document.querySelector('.__ModalMenu__');
  if (menuToggle) {
    menuToggle.addEventListener('click', function () {
      menu.classList.toggle('showMenu');
    });
  }

  $(document).ajaxSend(function (e, xhr, options) {});
});

//Generic Ajax Handler
function setTheRequest(request, data_type = 'html') {
  request = $.ajax({
    url: request.url,
    type: request.type,
    data: request.data,
    dataType: data_type,
  });
  return request;
}

//GA Track Add To Cart Action
function addToCartGA() {
  if (typeof ga == 'function') {
    ga('send', 'event', {
      eventCategory: 'Add Item',
      eventAction: 'click',
      eventLabel: 'Add To Cart',
    });
  }
}

//GA Track Email Subscribe Action
function addEmailAddressGA() {
  if (typeof ga == 'function') {
    ga('send', 'event', {
      eventCategory: 'Sign Up',
      eventAction: 'submit',
      eventLabel: 'Add Email',
    });
  }
}

//FB Track Lead
function fbqLead() {
  if (typeof fbq === 'function') {
    fbq('track', 'Lead');
  }
}

//FB Track Add To Cart
function fbqAddToCart() {
  if (typeof fbq === 'function') {
    fbq('track', 'AddToCart');
  }
}

jQuery(function ($) {
  $('[data-toggle="tooltip"]').tooltip();
});

jQuery(function ($) {
  $(document).on('click', '#crop', function () {
    ViewImage();
  });

  $(document).on('click', '#close', function () {
    closeModel();
  });
});

function ViewImage() {
  $('#staticBackdrop').toggle();
}

function closeModel() {
  window.$('#productImage').modal();
  $('#staticBackdrop').toggle();
  $('#iconsPreview').toggle();
  $('#logoPreview').toggle();
  $('#productPreview').toggle();
}

window.addEventListener('DOMContentLoaded', function () {
  $('#productImg').change(function (event) {
    window.$('#productImage').modal();
  });
});

function buttonUp() {
  $('#button1').hide();
  $('#button2').show();
}

$(document).on('click', '#randomz', function () {
  console.log('onClick Random');
  let rs = (Math.random() + 1).toString(36).substring(2);
  $('#optimizer_utm_campaign').val(rs);
});

window.searchGroup = function (item) {
  console.log(item.value);
  if (item.value.length >= 1) {
    var request = $.ajax({
      url: '/searches/group',
      type: 'get',
      data: {
        text: item.value,
      },
    });
  }
};

window.selectGroup = function (group_id, group_name, isNew) {
  $('#group_display').val(group_name);
  if (isNew) {
    $('#groupname').val(group_name);
  } else {
    $('#groupname').val(group_id);
  }
  $('.white-bg').remove();
};

// Start: Sync Activiation
window.syncActive = function () {
  $('.page-sync').addClass('page-sync-ac');
};

// Start: Sync Menu Active
window.syncItem = function () {
  $('.page-sync-item').addClass('sy-ac');
};

// Variable Selected Checkbox, Column Name, Section and Value (Main Experiment to Variable and Variable to Variable)

window.selectedAll = function (
  pageID,
  id,
  index,
  section_id,
  columnName,
  cnt_index,
) {
  let selID = '#sel' + id;
  let groupID = '.ch_' + id + '_group:checkbox';
  let variableID = '.vari_' + id;
  let item = $(selID).is(':checked');

  $(groupID).prop('checked', item);
  $(variableID).prop('checked', item);

  all_sync_values(item, pageID, id, section_id, index, cnt_index, columnName);
};

window.checkedGroup = function (
  pageID,
  id,
  group,
  section_id,
  index,
  columnName,
  cnt_index,
) {
  let activeGroup = '#ch_' + id + 'group_' + group;
  let item = $(activeGroup).is(':checked');
  $(activeGroup).prop('checked', item);
  let variId = '.vari_' + id + '_group_' + group;
  $(variId).prop('checked', !item);

  all_sync_values(true, pageID, id, section_id, index, cnt_index, columnName);
};

window.checkedVariable = function (
  pageID,
  varid,
  id,
  section_id,
  index,
  columnName,
  cnt_index,
) {
  let variableID = '#chvar_' + varid;
  let item = $(variableID).is(':checked');
  $(variableID).prop('checked', item);
  all_sync_values(true, pageID, id, section_id, index, cnt_index, columnName);
};

// Start: Sync All Variable values
function all_sync_values(
  item,
  pageID,
  id,
  section_id,
  index,
  cnt_index,
  columnName,
) {
  if (item) {
    let txt = '';
    if (columnName == 'section_image') {
      txt = id;
    } else if (columnName == 'section_image_bg') {
      txt = id;
    } else if (columnName == 'header_image') {
      txt = id;
    } else if (columnName.includes('product')) {
      txt = id;
    } else if (columnName.includes('content')) {
      txt = id;
    } else if (columnName.includes('plan')) {
      txt = id;
    } else if (columnName.includes('checkout')) {
      txt = '';
    } else {
      txt = section_id;
    }

    let checked = '.vari_' + id + ':checked';
    let letAss = $(checked)
      .map(function () {
        return $(this).data('id');
      })
      .get();
    var data =
      'page_id=' +
      pageID +
      '&section_id=' +
      section_id +
      '&sec_index=' +
      index +
      '&cnt_index=' +
      cnt_index +
      '&columnName=' +
      columnName +
      '&cvalue=' +
      escape(txt) +
      '&variable_ids=' +
      letAss;
    var request = setTheRequest({
      type: 'GET',
      url: '/searches/section',
      data: data,
    });

    request.done(function (response) {
      let titleDiv = 'til_' + id;
      let cntDev = '#till_' + id;
      let title = 'Synced - ' + letAss.length + ' Variables';
      $(cntDev).addClass('page-synced');
      $(titleDiv).val(title);
      document.getElementById(titleDiv).text = title;
    });
    request.fail(function (jqXHR, textStatus) {});
  } else {
  }
}

function syncTitle(id) {
  // $('.page-sync').addClass('page-synced')
  document.getElementById(synid).text = 'Synced - ' + itemArr.length;
}

window.activeMenu = function (id) {
  let secondTitle = '.second_' + id;
  let firstID = '.first_' + id;
  let thirdID = '.third_' + id;
  $(firstID).hide();
  $(thirdID).hide();
  $(secondTitle).addClass('m-display');
};

window.hideMenu = function (id) {
  let firstID = '.first_' + id;
  let thirdID = '.third_' + id;
  let secondTitle = '.second_' + id;
  let titleID = '#til_' + id;
  let replID = '#th_title_' + id;
  let txt = $(titleID).text();

  if (!txt.includes('-')) {
    console.log('system ');
    $(firstID).show();
    $(secondTitle).removeClass('m-display');
    console.log(txt);
  } else {
    $(thirdID).show();
    if (txt.length > 6) {
      $(secondTitle).removeClass('m-display');
      $(thirdID).addClass('sync-third');
      $(replID).text(txt);
    }
  }
};

// Start: Site Move Menu Script
window.opensiteMenu = function (id) {
  $('.lt-menu').show();
  $('.lt-menus').removeClass('ls-ac');
  $('.not-work-space').removeClass('ls-ac');
  let firstID = '.ma_' + id;
  let secID = '.mb_' + id;
  let thID = '.mc-' + id;
  $(firstID).hide();
  $(secID).addClass('ls-ac');
  $(thID).show();
};

window.openWorkspcae = function (id) {
  let thID = '.mc-' + id;
  let wpID = '.md-' + id;
  $(thID).hide();
  $(wpID).addClass('ls-ac');
};

window.openProject = function (id) {
  let thID = '.mc-' + id;
  let prID = '.me-' + id;
  $(thID).hide();
  $(prID).addClass('ls-ac');
};

window.moveSitetoWp = function (site_id, workspace_id) {
  var data = 'site_id=' + site_id + '&workspace_id=' + workspace_id;
  var request = setTheRequest({
    type: 'POST',
    url: '/sites/move_site_workspace',
    data: data,
  });

  request.done(function (response) {
    console.log('loading page');
    window.location.reload();
  });
  request.fail(function (jqXHR, textStatus) {});
};

window.moveSiteProject = function (site_id, project_id) {
  var data = 'site_id=' + site_id + '&project_id=' + project_id;
  var request = setTheRequest({
    type: 'POST',
    url: '/sites/move_site_project',
    data: data,
  });

  request.done(function (response) {
    console.log('loading page');
    window.location.reload();
  });
  request.fail(function (jqXHR, textStatus) {});
};

window.moveProjectWorkspace = function (workspace_id, project_id) {
  var data = 'workspace_id=' + workspace_id + '&project_id=' + project_id;
  var request = setTheRequest({
    type: 'POST',
    url: '/sites/move_project_workspace',
    data: data,
  });

  request.done(function (response) {
    window.location.reload();
  });
  request.fail(function (jqXHR, textStatus) {});
};

window.searchProjectDomain = function () {
  let dm = $('#name').val();
  if (dm) {
    $('.search').addClass('sactive');
    $('.search').text('Searching ...');
  }
};

window.openRedAlert = function (id) {
  let a = '.red-a' + id;
  let b = '.red-b' + id;
  $(b).hide();
  $(a).show();
};

window.nextDeletion = function (id) {
  let a = '.red-a' + id;
  let b = '.red-b' + id;
  $(a).hide();
  $(b).show();
  let rand1 = Math.random().toString(36).substring(2, 7);
  let rand2 = Math.random().toString(36).substring(2, 7);
  let rand3 = Math.random().toString(36).substring(2, 3);
  let nmp = rand1 + '-' + rand2 + '-' + rand3;

  let cod = '#code' + id;
  let del = '#del-inp' + id;

  $(cod).text(nmp);
  $(del).val(nmp);
};

window.deleteWorkspace = function (workspace_id) {
  let cod = '#int-inp' + workspace_id;
  let del = '#del-inp' + workspace_id;

  let codea = $(cod).val();
  let codeB = $(del).val();

  if (codea == codeB) {
    $.ajax({
      url: ' /workspaces/' + workspace_id,
      type: 'DELETE',
    });
  } else {
    swal('Command Code incorrect');
  }
};

window.dismissSiteNotification = function (notification_id) {
  if (notification_id) {
    $.ajax({
      url: ' /notifications/' + notification_id,
      type: 'DELETE',
    });
  }
};

window.createSiteNotification = function (site_id, user_id, notification_type) {
  if (site_id && user_id && notification_type) {
    const request = $.ajax({
      url: ' /notifications',
      type: 'POST',
      data: {
        notification: {
          site_id,
          user_id,
          notification_type
        }
      },
    });

    request.done(function (response) {
      window.location.reload();
    });
  }
};